import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Banner from "../components/banner"
import Description from "../components/description"

const IndexPage = () => {
    return (
        <Layout>
            <Banner />
            <Description />
        </Layout>
    )
}

export default IndexPage
