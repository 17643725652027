import React from 'react'

import './description.scss'
import Travelog from '../images/Travelog.png'
import Share from '../images/Share.png'
import Search from '../images/Search.png'

const Description = () => {
    return (
        <div className='description'>
            <div className='container'>
                <div className='half'>
                    <h1 className='title'>
                        Store
                    </h1>
                    <p>
                        All your travel memories in one convenient place.
                        Store photos and venues in a convenient way to always remember where you've been.
                    </p>
                </div>
                <div className='half screen'>
                    <img src={Travelog} />
                </div>
            </div>
            <div className='container reverse'>
                <div className='half'>
                    <h1 className='title'>
                        Share
                    </h1>
                    <p>
                        Share the places you've been with friends and family by sending a link to travel through imessage or by posting on any of your favorite social platforms.
                    </p>
                </div>
                <div className='half screen'>
                    <img src={Share} />
                </div>
            </div>
            <div className='container'>
                <div className='half'>
                    <h1 className='title'>
                        Discover
                    </h1>
                    <p>
                        Find friends and discover the cool places they've visited. Who better to get recommendations for your next trip than the people you trust most.
                    </p>
                </div>
                <div className='half screen'>
                    <img src={Search} />
                </div>
            </div>
        </div>
    )
}

export default Description
